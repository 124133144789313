<template>
    <img class="img-fluid" :src="source"/>
</template>

<script>
export default {
    props: {
        source: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped>
.img-fluid {
    width: 100%;
    width: auto;
    max-height: 500px;
}
</style>
